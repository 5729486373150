import axios from 'axios'
import store from '@/store'
// 根据环境不同引入不同api地址
import { baseApi } from '@/config'
// create an axios instance
const service = axios.create({
  baseURL: baseApi, // url = base api url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request拦截器 request interceptor
service.interceptors.request.use(
  config => {
    // 不传递默认开启loading
    if (!config.hideloading) {
    }
    if (store.getters.idToken) {
      config.headers.idToken = store.getters.idToken
    }
    if (config.url === '/forwardBiddingPrice/forwardFile') {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)
// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // console.log('response: ' + JSON.stringify(response))
    if (res.status && res.status !== 200) {
      // 登录超时,重新登录
      if (res.status === 401 || res.status === 403) {
        store.dispatch('logout').then(() => {
          location.reload()
        })
      }
      return Promise.reject(res || 'error')
    } else {
      return Promise.resolve(res)
    }
  },
  error => {
    if (error.response) {
      const res = error.response.data
      if (res.code === 401 || res.code === 403) {
        store.dispatch('logout').then(() => {
          location.reload()
        })
      }
    }
    return Promise.reject(error)
  }
)

export default service
