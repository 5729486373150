const api = {
  ForwardBG: '/webApi/forwardDateBackground/forward',
  ForwardBID: '/webApi/forwardBiddingPrice/forward',
  OldForwardBG: '/forwardDateBackground/forward',
  OldForwardBID: '/forwardBiddingPrice/forward',
  GetBannerByVipLevel: '/webApi/blBanner/getByVipLevel',
  GetRelevancy: '/webApi/tQualificationRelevancy/getAllQualificationRelevancy', // 获取所有资质
  GetAllArtisanRelevancy: '/webApi/tArtisanRelevancy/getAllArtisanRelevancy',
  GetMatchingAllMayHonorRelevancy: '/webApi/tHonorRelevancy/getAllHonorRelevancy',
  GetAdviceByCode: '/wxApi/companyScore/getAdviceByCode', // 分数解读
  GetCompanyRankByCode: '/wxApi/companyScore/getCompanyRankByCode', // 分数数组
  GetCompanyScoreByName: '/wxApi/companyScore/getCompanyScoreByName',
  GetProposalByCode: '/wxApi/companyScore/getProposalByCode',
  GetCountryBankScoreByNameUrl: '/wxApi/companyScore/getCountryBankScoreByNameUrl',
  GetCountryBankProposalByNameUrl: '/wxApi/companyScore/getCountryBankProposalByNameUrl',
  Login: '/user/login',
  UserInfo: '/user/userinfo',
  UserName: '/user/name',
  GetVipPlan: '/webApi/blVipPlan/getVipPlan', // 获取会员方案
  GetActivity: '/wxApi/blActivityApi/getActivity', // 获取活动
  GetCouponInfoByActivityId: '/wxApi/blCouponApi/getCouponInfoByActivityId', // 获取活动优惠券
  ReceiveCoupon: '/wxApi/blCouponApi/receiveCoupon', // 领取活动优惠券
  GetCouponsByModel: '/wxApi/blCouponApi/getCouponsByModel', // 获取优惠券
  GetVipByUserId: '/wxApi/vip/getByUserId',
  UseCoupon: '/wxApi/blCouponApi/useCoupon', // 使用优惠券
  GetCreditReportByUserId: '/wxApi/creditReport/getByUserId',
  GetDownLoadUrlByReportIdOpen: '/wxApi/creditReport/getDownLoadUrlByReportId',
  GetDownLoadUrlByReportId: '/wxApi/creditReport/getDownLoadUrlByReportId/00120001',
  GetDownLoadUrlByReportIdPlus: '/wxApi/creditReport/getDownLoadUrlByReportId/00120002',
  GetDownLoadUrlByReportIdPlusFinance: '/wxApi/creditReport/getDownLoadUrlByReportId/00120003',
  CreditBasicsReport: '/wxApi/creditReport/getReport/00120001',
  CreditUpgradeReport: '/wxApi/creditReport/getReport/00120002',
  CreditUpgradeReportFinance: '/wxApi/creditReport/getReport/00120003',
  GetBlOrderByUserId: '/wxApi/blOrder/getByUserId',
  CreateWebReportOrder: '/wxApi/wxNativePay/createReportOrder',
  GetReportPayState: '/wxApi/wxNativePay/getReportPayState',
  CreateOrder: 'wxApi/wxNativePay/createOrder', // vip下单
  Queryorder: '/wxApi/wxPay/queryorder', // 支付查询回调
  GetVipPayState: '/wxApi/wxNativePay/getVipPayState', // 查询会员订单在支付状态
  GetIp: '/webApi/getIp',
  GetByTel: '/webApi/vip/getByTel',
  GetCompanyInfoByCode: '/wxApi/companyScore/getCompanyInfoByCode', // 通过社会统一代码换公司id
  GetFoucsByUserIdAndCompanyIds: '/webApi/userFoucs/getByUserIdAndCompanyIds', // 通过用户id和公司id组查询用户关注企业
  InsertUserFoucs: '/webApi/userFoucs/insertUserFoucs', // 通过用户id和公司id关注企业
  DelByUserIdAndCompanyId: '/webApi/userFoucs/delByUserIdAndCompanyId', // 通过用户id和公司id取消关注企业
  GetFoucsByUserId: '/wxApi/userFoucs/getByUserId',
  UpdateStatusByUserIdAndCompanyId: '/wxApi/userFoucs/updateStatusByUserIdAndCompanyId', // 更新用户关注企业的最新中标消息
  GetDictByModel: '/webApi/dict/getByModel', // 通过用户id和公司id取消关注企业
  GetUserInfoByIdToken: '/wxApi/vip/getUserInfoByIdToken', // 通过tokenId获取用户信息，有则返回，没有则新增
  ForwardFile: '/forwardBiddingPrice/forwardFile', // 转发到竞价上传文件
  GetOrdersByUserId: '/wxApi/blOrder/getReportAndVipByUserId', // 查询用户订单数据
  GetRelevancyUser: '/wxApi/relevancyUser/getByUserId', // 获取用户的关联账户
  AddRelevancyUser: '/wxApi/relevancyUser/insertRelevancyUser', // 删除关联账户
  DelRelevancyUser: '/wxApi/relevancyUser/delById', // 新增关联账户
  ApplyBillRecord: '/wxApi/billRecord/insertBillRecord', // 申请开票
  GetCreditData: '/webApi/companyPortrait/getCreditData', // 企业画像-信用数据总览
  GetIndustryLevelAvg: '/webApi/companyPortrait/getIndustryLevelAvg', // 企业画像-行业平均水平
  GetCompanyDevelopStatus: '/webApi/companyPortrait/getCompanyDevelopStatus', // 企业画像-企业发展情况
  GetCompanyInnovateAbility: '/webApi/companyPortrait/getCompanyInnovateAbility', // 企业画像-近五年创新能力
  GetCompanyCustomerIndustryDistribution: '/webApi/companyPortrait/getCompanyCustomerIndustryDistribution', // 企业画像-近五年客户行业分布
  GetCompanyAchievement: '/webApi/companyPortrait/getCompanyAchievement', // 企业画像-近五年中标趋势
  GetCompanyMarketExtension: '/webApi/companyPortrait/getCompanyMarketExtension', // 企业画像-市场扩展分布
  RefreshToken: '/sysUser/refreshToken', // 刷新idToken
  GetAccessTokenByCode: '/webApi/wxLogin/getAccessTokenByCode', // 通过code获取 access_token
  GetWxLoginParam: '/webApi/wxLogin/getParam', // 获取微信登录参数
  GetIpLocation: '/webApi/gaodeWebApi/getIpLocation', // 获取位置通过ip
  Offline: '/reportGenerate/offline', // 报告发邮件
  GetPayCount: '/reportGenerate/getPayCount',
  UpdateMobile: '/wxApi/vip/updateMobile',
  ForwardXmcic: '/webApi/forwardXmcic/forward'
}

export default api
